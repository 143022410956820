import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { AlertService } from '../../../../core/services/alert.service';
import { UiStore } from '../../../../core/store/ui.store';
import { AuditDetailsDraftReportStore } from './audit-details-draft-report.store';
import { AuditDetailsDraftFindingConversationFormConfig } from '../config/audit-details-draft-finding-conversation-form.config';
import { AuditDraftFindingConversationService } from '../services/audit-draft-finding-conversation.service';
import { AuditDraftFindingConversationFormDTO } from '../dto/audit-draft-finding-conversation.dto';
import { AuditDetailsDraftFindingConversationConversion } from '../conversion/audit-details-draft-finding-conversation.conversion';
import { ConfirmService } from '../../../../core/services/confirm.service';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';

@Injectable({ providedIn: 'root' })
export class AuditDetailsDraftFindingConversationFormStore {
  itemId: number | null = null;
  mainId: number | null = null;  
  isDeleting: boolean = false;

  showDiscussionPanel:boolean = false
  isCommentsLoading: boolean = false;
  isDetailsLoading: boolean = true;

  constructor(
    private auditDetailsDraftReportStore: AuditDetailsDraftReportStore,
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private auditDetailsDraftFindingConversationFormConfig: AuditDetailsDraftFindingConversationFormConfig,
    protected alertService: AlertService,
    protected conversion: AuditDetailsDraftFindingConversationConversion,
    protected uiStore: UiStore,
    protected service: AuditDraftFindingConversationService,
    protected confirmService: ConfirmService // for delete

  ) {
    makeObservable(this, {
      itemId: observable,
      mainId: observable,
      isDeleting: observable,
      isDetailsLoading: observable,
      resetEntireState: action,
      deleteConversation: action,
    });
  }

  initialize(): void {
   
  }

  loadItems(): void {
    this.auditDetailsDraftReportStore.loadDraftReport();
  }


  saveComment(eve: any) {
    this.uiStore.startSaving();
    const commentForm: AuditDraftFindingConversationFormDTO = {
      description: eve.comment,
      type: "AC",
    };
    this.service
      .create(this.mainId!, commentForm)
      .subscribe({
        next: (response: any) => {
          runInAction(() => {
            this.alertService.success(
              `A new comment has been successfully created.`,
              'Creation Successful'
            );
            this.uiStore.finishSaving();
            this.loadItems();
            this.showDiscussionPanel = true;
          });
        },
        error: (error: any) => {
          runInAction(() => {
            this.uiStore.finishSaving();
            console.error(`Failed to add comment:`, error);
            this.alertService.error('Error!', `Failed to add comment.`);
          });
        },
      });
  }




  openPanel(finding: any) {
    
    this.showDiscussionPanel = true;
    this.isDetailsLoading = false;
    this.mainId = finding.id;
  }

  closePanel() {
    this.showDiscussionPanel = false;
    this.isDetailsLoading = false;

    this.resetEntireState();
  }

  deleteConversation(finding:any, conversation: any): void {
    this.confirmService.confirmDeleteItem().then((isConfirmed: boolean) => {
      if (isConfirmed) {
        this.isDeleting = true;
        let ids = [conversation.id];

        this.service.delete(finding.id, ids)
          .subscribe({
            next: () => {
              this.isDeleting = false;
              this.removeConversationFromList(finding.id, conversation.id);
              this.alertService.success('Deleted!', 'Success');

            },
            error: (error: any) => {
              this.isDeleting = false;
              this.alertService.error('Error while deleting!', 'Error!');
              console.error('Error while deleting', error);
            },
          });
      }
    });
  }

  removeConversationFromList(findingId: number, id: number): void {
    this.auditDetailsDraftReportStore.auditDraftReportDocument?.draft_findings.find((finding) => {
      if (finding.id === findingId) { 
        finding.conversations = finding.conversations.filter((selectedConversation: any) => selectedConversation.id !== id);
      }
    })
  }

  resetEntireState(): void {
    runInAction(() => {
      this.itemId = null;
      this.mainId = null;
    
    });
  }
}
