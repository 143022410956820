  <div id="conclusion" class="col-md-12">
    <h4>Conclusion</h4>
    <div class="comment-section">
      <a href="javascript:void(0)" (click)="auditDetailsDraftReportCommentStore.openPanel('conclusion')" class="a-link">Comments</a>
    </div>
  </div>
  <div class="widget-block p-4" style="background: #f4f9f5">
    <div class="row">
      <div class="col-md-6">
        <label class="label-text">Concluded by </label>
        <p class="p-text">Ahamed Khan</p>
      </div>
      <div class="col-md-6">
        <label class="label-text">Concluded on </label>
        <p class="p-text">16th Jan 2024</p>
      </div>

      <div class="col-md-12">
        <p class="p-text">
          <app-read-more
            [text]="reportDetails.conclusion"
            [maxLength]="maxLength"
          ></app-read-more>
        </p>
      </div>
    </div>
  </div>
