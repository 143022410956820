import { Injectable } from '@angular/core';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { UiStore } from '../../../../core/store/ui.store';
import { AlertService } from '../../../../core/services/alert.service';
import { AuditDetailsStore } from './audit-details.store';

import { AuditDraftReportSectionDetailsDTO } from '../dto/audit-draft-report.dto';
import { AuditDetailsDraftReportStore } from './audit-details-draft-report.store';
import { AuditDraftReportService } from '../services/audit-draft-report.service';
import { AuditDetailsDraftReportCommentConversion } from '../conversion/audit-details-draft-report.comment.conversion';
import { AuditDraftReportCommentFormDTO, AuditDraftReportCommentListDTO } from '../dto/audit-draft-report-comment.dto';
import { ConfirmService } from '../../../../core/services/confirm.service';
import { CommentStatusFormDTO } from '../../../../shared/dto/discussion.dto';

@Injectable({ providedIn: 'root' })
export class AuditDetailsDraftReportCommentStore {
  auditId: number;
  reportId: number | undefined;

  showDiscussionPanel: boolean = false;
  isCommentsLoading: boolean = false;
  isButtonLoading: boolean = false;
  isCommentsEmptyList: boolean = true;

  comments: AuditDraftReportCommentListDTO[];
  draftReportSectionDetails: AuditDraftReportSectionDetailsDTO;
  draftReportSectionType: string;
  draftReportFindingId: number | null;
  isDetailsLoading: boolean = true;
  resolveStatus: boolean;
  sortField: string = 'id';
  sortOrder: string = 'desc';

  constructor(
    protected uiStore: UiStore,
    protected auditDetailsStore: AuditDetailsStore,
    protected auditDetailsDraftReportStore: AuditDetailsDraftReportStore,
    protected alertService: AlertService,
    protected auditDraftReportService: AuditDraftReportService,
    protected auditDetailsDraftReportCommentConv: AuditDetailsDraftReportCommentConversion,
    private confirmService: ConfirmService
  ) {
    makeObservable(this, {
      isButtonLoading: observable,
      isCommentsLoading: observable,
      isCommentsEmptyList: observable,
      openPanel: action,
      closePanel: action,
      getDraftReportSectionDetails: action,
      loadComments: action,
      updateCommentStatus: action,
      saveComment: action,
    });
  }

  openPanel(type: string, finding?:any) {
    this.auditId = this.auditDetailsStore.details.id;
    this.reportId = this.auditDetailsDraftReportStore.auditDraftReportDocument.id;
    this.showDiscussionPanel = true;
    this.draftReportSectionType = type;
    this.draftReportFindingId = finding ? finding.id : null;
    this.getDraftReportSectionDetails(type, finding);
    this.loadComments(type);
  }

  closePanel() {
    this.showDiscussionPanel = false;
    this.auditDetailsDraftReportStore.loadDraftReport();
    this.draftReportSectionType = '';
    this.draftReportFindingId = null;
    this.resetEntireState();
  }

  getDraftReportSectionDetails(type:string, finding?:any): void {
    if (!this.auditId && this.reportId) {
      console.error('Main ID and Sub ID must be set');
      throw new Error('Main ID and Sub ID must be set');
    }
    this.isDetailsLoading = true
    if(type == 'executive-summary'){
      this.draftReportSectionDetails = {
        id: this.auditDetailsDraftReportStore.auditDraftReportDocument.id,
        title: 'Executive Summary',
        description: this.auditDetailsDraftReportStore.auditDraftReportDocument.executive_summary || ''
      };
      
    }
    if(type == 'conclusion'){
      this.draftReportSectionDetails = {
        id: this.auditDetailsDraftReportStore.auditDraftReportDocument.id,
        title: 'Conclusion',
        description: this.auditDetailsDraftReportStore.auditDraftReportDocument.conclusion || ''
      };
      
    }
    if(type == 'finding'){
      this.draftReportSectionDetails = {
        id: this.auditDetailsDraftReportStore.auditDraftReportDocument.id,
        title: finding.title,
        description: finding.description
      };
      
    }
    this.isDetailsLoading = false

    
  }


  updateCommentStatus(commentStatus: CommentStatusFormDTO) {
    if (!this.auditId || !this.reportId) {
      console.error('Audit ID or Report ID is not defined');
      throw new Error('Audit ID or Report ID is required');
    }
  
    const confirmAction = commentStatus.is_resolve
      ? this.confirmService.confirmResolveItem()
      : this.confirmService.confirmReopenItem();
  
    confirmAction.then((isConfirmed: boolean) => {
      if (isConfirmed) {
        this.isButtonLoading = true;
        if (this.reportId) {
          this.auditDraftReportService
            .updateCommentStatus(this.auditId, this.reportId, commentStatus.comment_id, commentStatus.is_resolve)
            .subscribe({
              next: () => {
                this.loadComments(this.draftReportSectionType);
                if (commentStatus.is_resolve) {
                  this.alertService.success('Successfully resolved', 'Success');
                } else {
                  this.alertService.success('Successfully reopened', 'Success');
                }
  
                runInAction(() => {
                  this.isButtonLoading = false;
                });
              },
              error: (err: any) => {
                this.alertService.error('Failed to update comment status.', 'error');
                runInAction(() => {
                  this.isButtonLoading = false;
                });
              },
            });
        }
      }
    });
  }
  

  loadComments(type: string): void {
    this.isCommentsLoading = true;
    if (!this.auditId && !this.reportId) {
      console.error('Adit details ID or Report ID is missing');
      return;
    }  

    let dynamicFilters: { [key: string]: number[] } | undefined;
    if (type == 'finding' && this.draftReportFindingId) {
      dynamicFilters = {
        audit_draft_finding_ids: [this.draftReportFindingId]  // Set the dynamic filter
      };
    }
    
    this.auditDraftReportService
      .getCommentList(this.auditId, this.reportId!, type, this.sortField, this.sortOrder, dynamicFilters)
      .subscribe({
        next: (response: any) => {
          runInAction(() => {
            this.isCommentsEmptyList = response.length == 0;
            this.comments =
              this.auditDetailsDraftReportCommentConv.resToList(response);
              // generate download url  
              this.comments.forEach((item) => {
                  if (this.auditId && item?.documents.length>0) {
                    item.documents.forEach((document) => {
                      if(document.token){
                        document.downloadUrl = this.auditDraftReportService.getCommentDownloadURL(
                          this.auditId,
                          item.audit_draft_report_id,
                          item.id,
                          document.token
                        );
                      }
                    })
                   
                  }
                });
            this.isCommentsLoading = false;
          });
        },
        error: (error: any) => {
          console.error('Failed to load data:', error);
          this.isCommentsLoading = false;
        },
      });
  }

  saveComment(eve: any) {
    this.uiStore.startSaving();
    const commentForm: AuditDraftReportCommentFormDTO = {
      comment: eve.comment,
      documents: eve.documents,
      type: this.draftReportSectionType,
      audit_draft_finding_id: this.draftReportFindingId ? this.draftReportFindingId : null 
    };
    this.auditDraftReportService
      .saveComment(this.auditId, this.reportId!, commentForm)
      .subscribe({
        next: (response: any) => {
          runInAction(() => {
            this.alertService.success(
              `A new comment has been successfully created.`,
              'Creation Successful'
            );
            this.uiStore.finishSaving();
            this.loadComments(this.draftReportSectionType);
          });
        },
        error: (error: any) => {
          runInAction(() => {
            this.uiStore.finishSaving();
            console.error(`Failed to add comment:`, error);
            this.alertService.error('Error!', `Failed to add comment.`);
          });
        },
      });
  }

  resetEntireState() {
    this.showDiscussionPanel = false;
    this.isCommentsLoading = false;
    this.isCommentsEmptyList = true;
    this.isButtonLoading = false;
    this.draftReportSectionDetails = {} as AuditDraftReportSectionDetailsDTO;
    this.comments = [];
  }
}
