import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuditChecklistFindingDetailsDTO } from '../../../../../dto/audit-checklist-finding.dto';
import { AccordionItemComponent } from '../../../../../../../../shared/components/accordions/accordion-item/accordion-item.component';
import { LabelDisplayComponent } from '../../../../../../../../shared/components/layout/details/label-display/label-display.component';
import { ReadMoreComponent } from '../../../../../../../../shared/components/layout/details/read-more/read-more.component';
import { AuditDetailsReportDetailsConversationsComponent } from '../../audit-details-report-details-conversations/audit-details-report-details-conversations.component';
import { AuditDetailsReportDetailsActionPlanComponent } from '../../audit-details-report-details-action-plan/audit-details-report-details-action-plan.component';
import { TruncatePipe } from '../../../../../../../../core/pipes/truncate.pipe';
import { FileSizePipe } from '../../../../../../../../core/pipes/file-size.pipe';
import { DraftReportFindingStatusComponent } from '../draft-report-finding-status/draft-report-finding-status.component';


@Component({
  selector: 'app-draft-report-finding-item',
  standalone: true,
  imports: [
    AccordionItemComponent,
    LabelDisplayComponent,
    ReadMoreComponent,
    AuditDetailsReportDetailsConversationsComponent,
    AuditDetailsReportDetailsActionPlanComponent,
    TruncatePipe,
    FileSizePipe,
    DraftReportFindingStatusComponent,
  ],
  templateUrl: './draft-report-finding-item.component.html',
  styleUrl: './draft-report-finding-item.component.scss',
})
export class DraftReportFindingItemComponent {
  @Input() draftFinding: AuditChecklistFindingDetailsDTO;
  @Input() isInDepartmentReview: boolean = false;
  @Input() isDraftOrInReview:boolean = true;

  maxLength: number = 100;
  isFindingEditButton: boolean = false;

  @Output() openFindingFormRequest =
    new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() openConversationPanelRequest =
    new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() findingDeleteRequest = new EventEmitter<number>();

  constructor(
  ) {}

  openEditFindingForm(item: AuditChecklistFindingDetailsDTO) {
    this.openFindingFormRequest.emit(item);
  }

  openFindingConversationPanel(item: AuditChecklistFindingDetailsDTO) {
    this.openConversationPanelRequest.emit(item);
  }

  deleteFinding(findingId: number) {
    this.findingDeleteRequest.emit(findingId);
  }
}
