import { Component, Input } from '@angular/core';
import { AccordionItemComponent } from '../../../../../../../shared/components/accordions/accordion-item/accordion-item.component';
import { AuditChecklistFindingDetailsDTO } from '../../../../dto/audit-checklist-finding.dto';
import { AuditDetailsActionPlanFormStore } from '../../../../store/audit-details-action-plan-form.store';
import { DateTimeFormatPipe } from '../../../../../../../core/pipes/date-time-format.pipe';
import { LabelDisplayComponent } from '../../../../../../../shared/components/layout/details/label-display/label-display.component';
import { AuditDetailsDraftReportStore } from '../../../../store/audit-details-draft-report.store';

@Component({
  selector: 'app-audit-details-report-details-action-plan',
  standalone: true,
  imports: [
    AccordionItemComponent,
    DateTimeFormatPipe,
    LabelDisplayComponent
  ],
  templateUrl: './audit-details-report-details-action-plan.component.html',
  styleUrl: './audit-details-report-details-action-plan.component.scss'
})
export class AuditDetailsReportDetailsActionPlanComponent {
  @Input() draftFinding: AuditChecklistFindingDetailsDTO;

  constructor(
    public auditDetailsActionPlanFormStore: AuditDetailsActionPlanFormStore,
    public auditDetailsDraftReportStore: AuditDetailsDraftReportStore,
  ) {}
}
