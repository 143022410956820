import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuditDraftReportDocumentDTO } from '../../../../../dto/audit-draft-report.dto';
import { DateTimeFormatPipe } from '../../../../../../../../core/pipes/date-time-format.pipe';
import { AuditDetailsDraftReportCommentStore } from '../../../../../store/audit-details-draft-report-comment.store';

@Component({
  selector: 'app-draft-report-overview',
  standalone: true,
  imports: [DateTimeFormatPipe],
  templateUrl: './draft-report-overview.component.html',
  styleUrl: './draft-report-overview.component.scss',
})
export class DraftReportOverviewComponent {
  @Input() reportDetails: AuditDraftReportDocumentDTO;

  @Output() openCommentPanelRequest = new EventEmitter<string>();
  @Output() closeCommentPanelRequest = new EventEmitter<string>();

  constructor(
    public auditDetailsDraftReportCommentStore: AuditDetailsDraftReportCommentStore
  ) {}

  requestToOpenCommentPanel(type: string) {
    this.openCommentPanelRequest.emit(type);
  }

  requestToCloseCommentPanel(type: string) {
    this.closeCommentPanelRequest.emit(type);
  }
}
