<div class="widget-block bg-light-grey">
  <div class="row">
    <div class="col-md-3">
      <app-draft-report-index [reportDetails]="reportDetails"></app-draft-report-index>
    </div>
    <div class="col-md-9 draft-report">
      <app-draft-report-cover-page [reportDetails]="reportDetails"></app-draft-report-cover-page>

      <div id="overview" class="draft-page">
       <app-draft-report-overview
        (openCommentPanelRequest)="openCommentPanel($event)"
        [reportDetails]="reportDetails">
       </app-draft-report-overview>

       @if(reportDetails.draft_findings){
        <div id="findings" class="row">
          <h4>Findings</h4>
          <div class="col-md-12">
            @for (draftFinding of reportDetails.draft_findings; track draftFinding){
              <app-draft-report-finding-item
                [draftFinding]="draftFinding"
                (openFindingFormRequest)="openFindingForm($event)"
                (openConversationPanelRequest)="openFindingConvesationPanel($event)"
                (findingDeleteRequest)="deleteFinding($event)">
              </app-draft-report-finding-item>
            }
          </div>
        </div>
        }
        <app-draft-report-conclusion [reportDetails]="reportDetails"></app-draft-report-conclusion>
      </div>
    </div>
  </div>
</div>


<!-- //converation component panel -->
