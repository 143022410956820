import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { convertResponseToFileDetailsDTO } from '../../../../shared/dto/file.dto';
import { AuditDraftReportCommentListDTO } from '../dto/audit-draft-report-comment.dto';

@Injectable({
  providedIn: 'root',
})
export class AuditDetailsDraftReportCommentConversion
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private userConv: UserConversion,

  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): AuditDraftReportCommentListDTO[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        audit_document_request_id: item.audit_document_request_id,
        commented_user: this.userConv.resToUserMinimalIdFixedDTO(item, 'created_user'),
        comment: item.comment,
        commented_at: item.created_at,
        documents: item.documents.map((document:any) => convertResponseToFileDetailsDTO(document)),
        audit_draft_report_id: item.audit_draft_report_id,
        is_resolved: item.is_resolved,
      
      };
    });
    return result;
  }
  
 
}
