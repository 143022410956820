import { Component, Input } from '@angular/core';
import { ReadMoreComponent } from '../../../../../../../../shared/components/layout/details/read-more/read-more.component';
import { AuditDraftReportDocumentDTO } from '../../../../../dto/audit-draft-report.dto';
import { AuditDetailsDraftReportCommentStore } from '../../../../../store/audit-details-draft-report-comment.store';

@Component({
  selector: 'app-draft-report-conclusion',
  standalone: true,
  imports: [ReadMoreComponent],
  templateUrl: './draft-report-conclusion.component.html',
  styleUrl: './draft-report-conclusion.component.scss',
})
export class DraftReportConclusionComponent {
  maxLength: number = 250;
  @Input() reportDetails: AuditDraftReportDocumentDTO;

  constructor(
    public auditDetailsDraftReportCommentStore: AuditDetailsDraftReportCommentStore,
  ) {}
}
