<div class="row mt-3">
    @if(draftFinding.conversations.length>0 && auditDetailsDraftReportStore.isDraft()){
        <div class="col-md-12 d-flex align-items-center justify-content-between">
            <h4>Latest Responses</h4> 
            <a href="javacript:void(0)" (click)="auditDetailsDraftFindingConversationFormStore.openPanel(draftFinding)" class="a-link">View All Responses</a>
        </div>
        @if(draftFinding.latest_response){
        <div class="col-md-12">
            <div class="sm-widget-block ">
                <div>
                    <div class="d-flex ">
                        <h5 class="me-2 font-md d-flex align-items-center"> {{ draftFinding.latest_response.commented_user?.first_name + ' ' + draftFinding.latest_response.commented_user?.last_name }}</h5>
                        @if(draftFinding.latest_response.type == 'AC'){
                            <small>Auditor Comment</small>
                        }@else if(draftFinding.latest_response.type == 'DR'){
                            <small>Department Response</small>
                        }
                    </div>
                    <p class="mb-1">
                        <app-read-more [text]="draftFinding.latest_response.comment || ''" [maxLength]="maxLength"></app-read-more>
                    </p>
                
                </div>
            </div>
        </div>
        }
    } @else {
        <div class="col-md-12">
            <div class="widget-block widget-grey-bg text-center d-flex align-items-center justify-content-center ">
                <div>
                    <h4 class="text-center">No Department Response</h4>
                    <p>Check back soon for updates</p>
                    @if(auditDetailsDraftReportStore.isDraft()){
                        <button (click)="auditDetailsDraftFindingConversationFormStore.openPanel(draftFinding)" type="button" class="btn btn-primary btn-theme">Add Response</button>
                    }
                </div>
            </div>
        </div>
    }



    <app-discussion-panel-layout
        [title]="'Finding'"
        [isShowPanel]="auditDetailsDraftFindingConversationFormStore.showDiscussionPanel"
        [details]="draftFinding"
        [isDetailsLoading]="auditDetailsDraftFindingConversationFormStore.isDetailsLoading"
        [isCommentLoading]="auditDetailsDraftFindingConversationFormStore.isCommentsLoading"
        [comments]="draftFinding.conversations"
        (saveCommentRequest)="auditDetailsDraftFindingConversationFormStore.saveComment($event)"
        (closePanelRequest)="auditDetailsDraftFindingConversationFormStore.closePanel()">
    </app-discussion-panel-layout>
</div>


