<div class="alert-danger d-flex mb-3">
  <img
    class="me-1"
    src="assets/images/audit-icons/icon-flag.svg"
    alt="Overview Icon"
  />Challenged by Ibrahim Ahmed on April 14, 2024
  <a class="a-link text-danger" href="#"
    >12 responses (2 New)</a
  >
</div>
