import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { SubCRUDServiceInterface } from '../../../../core/interfaces/service/sub-crud-service.interface';
import { AuditDraftFindingConversationFormDTO } from '../dto/audit-draft-finding-conversation.dto';

@Injectable({
  providedIn: 'root',
})
export class AuditDraftFindingConversationService implements SubCRUDServiceInterface {
  protected apiUrl = `${environment.apiUrl}/audit-draft-findings`;

  constructor(protected http: HttpClient) {}


  list(
    findingId: number,
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/${findingId}/conversations`,
    );
  }

  create(findingId: number, item: AuditDraftFindingConversationFormDTO) : Observable<AuditDraftFindingConversationFormDTO>{
    const url =  `${this.apiUrl}/${findingId}/conversations`
    return this.http.post<AuditDraftFindingConversationFormDTO>(`${url}`, item);
  }

  get(findingId: number, conversationId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${findingId}/conversations/${conversationId}`);
  }

  update(findingId: number, conversationId: number, item: AuditDraftFindingConversationFormDTO) : Observable<AuditDraftFindingConversationFormDTO>{
    const url =  `${this.apiUrl}/${findingId}/conversations/${conversationId}`
    return this.http.put<AuditDraftFindingConversationFormDTO>(`${url}`, item);
  }

  delete(findingId: number, ids: number[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/${findingId}/conversations/delete`, {
      ids,
    });
  }

  

}
