import { Injectable } from '@angular/core';
import { FormSection } from '../../../../shared/models/form.config';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';

@Injectable({ providedIn: 'root' })
export class AuditDetailsDraftFindingConversationFormConfig {
  formSections: FormSection[] = [
    {
      key: 'audit_details_draft_report_finding_conversation',
      title: '',
      fields: [
        
        {
          id: 'type',
          label: 'Type',
          formControlName: 'Type',
          type: 'dropdown',
          placeholder: 'Select type',
          size: 6,
          isInfoIcon: false,
          validators: ['required'],
          options: [
            { id: 'DR', title: 'DR' },
            { id: 'AC', title: 'AC' },
          ],
        },
        
        {
          id: 'description',
          label: 'Description',
          formControlName: 'Description',
          type: 'textarea',
          placeholder: 'Enter description',
          size: 12,
          validators: ['required'],
        },
       
      ],
    },
  ];

  constructor(private userPreference: CurrentUserPreferenceStore) {}
 
}
