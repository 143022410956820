import { Component, Input } from '@angular/core';
import { AuditDetailsDraftFindingConversationFormStore } from '../../../../store/audit-details-draft-finding-conversation-form.store';
import { AuditChecklistFindingDetailsDTO } from '../../../../dto/audit-checklist-finding.dto';
import { SideFormComponent } from '../../../../../../../shared/components/forms/side-form/side-form.component';
import { SideFormModalComponent } from '../../../../../../../shared/components/modals/side-form-modal/side-form-modal.component';
import { ReadMoreComponent } from '../../../../../../../shared/components/layout/details/read-more/read-more.component';
import { DiscussionPanelLayoutComponent } from '../../../../../../../shared/components/layout/discussion-panel/discussion-panel-layout/discussion-panel-layout.component';
import { AuditDetailsDraftReportStore } from '../../../../store/audit-details-draft-report.store';

@Component({
  selector: 'app-audit-details-report-details-conversations',
  standalone: true,
  imports: [
    SideFormComponent,
    SideFormModalComponent,
    ReadMoreComponent,
    DiscussionPanelLayoutComponent
  ],
  templateUrl: './audit-details-report-details-conversations.component.html',
  styleUrl: './audit-details-report-details-conversations.component.scss'
})
export class AuditDetailsReportDetailsConversationsComponent {
  @Input() draftFinding: AuditChecklistFindingDetailsDTO;
  maxLength: number = 250;

  constructor(
    public auditDetailsDraftFindingConversationFormStore: AuditDetailsDraftFindingConversationFormStore,
    public auditDetailsDraftReportStore: AuditDetailsDraftReportStore,
  ) {}



}
