import { Component } from '@angular/core';

@Component({
  selector: 'app-draft-report-finding-status',
  standalone: true,
  imports: [],
  templateUrl: './draft-report-finding-status.component.html',
  styleUrl: './draft-report-finding-status.component.scss'
})
export class DraftReportFindingStatusComponent {

}
